import { useState, useCallback, useRef, useEffect } from 'react';
import { Accordion, Animation } from 'rsuite';

import update from 'immutability-helper'

import { STORE } from '../../store';
import { useAsyncAppState  } from '../../hooks/useAsyncAppState';
import { 
  resolveNodeFile, 
  loadNodesNames, 
  loadNodes,
  saveNode,
  deleteNode,
  normalize
} from '../../services/logicViewer';

import LoaderWrapper from '../../components/common/LoaderWrapper';
import UploadDocsForm from '../shared/uploader/UploadDocsForm';
import useConfirmDialog from '../shared/confirm/useConfirmDialog';
import LogicViewerLayout from './LogicViewerLayout';
import ViewerPanel from './ViewerPanel';
import OpenDialog, { FILES_OPEN_ALL, FILES_OPEN_ONE } from './OpenDialog';
import SaveDialog from './SaveDialog';

import './logicViewer.scss';

const LogicViewerPage = () => {
  const [result, setResult] = useState(null);
  const [viewUrl, setViewUrl] = useState('');
  const [nodesOpen, setNodesOpen] = useState(null);
  const [nodeSave, setNodeSave] = useState(null);
  const [linkFrom, setLinkFrom] = useState(null);
  const [expandedIds, setExpandedIds] = useState([]);
  const [activeId, setActiveId] = useState(null);
  const [uploaderVisible, setUploaderVisible] = useState(false);
  const saveTimeout = useRef(null);

  const [confirmDeleteAsync, confirmDeleteRender] = useConfirmDialog('Logic File Delete', 'Yes');
  const [resolvedFile, resolveFileAsync] = useAsyncAppState(STORE.areas.logicViewer.resolve, resolveNodeFile);
  const [loadedNames, loadNamesAsync] = useAsyncAppState(STORE.areas.logicViewer.loadNames, loadNodesNames);
  const [loadedNodes, loadNodesAsync] = useAsyncAppState(STORE.areas.logicViewer.loadNodes, loadNodes);
  const [savedNode, saveNodeAsync] = useAsyncAppState(STORE.areas.logicViewer.saveNode, saveNode);
  const [deletedNode, deleteNodeAsync] = useAsyncAppState(STORE.areas.logicViewer.delete, deleteNode);

  // Load Events
  const processUploadFiles = useCallback(async (names) => {
    const newResult = [];
    for (let name of names) {
      try {
        const data = await resolveFileAsync(name);
        newResult.push(normalize(data));
      }
      catch {
        console.log(`File ${name} is invalid`);
      }
    }
    setResult(newResult);
  }, [setResult, resolveFileAsync]);

  const onUpload = useCallback(async (uploadResult) => {
    processUploadFiles(uploadResult.map((item) => item.file.name));
  }, [processUploadFiles]);

  const onLoadFiles = useCallback(async () => {
    setNodesOpen(FILES_OPEN_ALL);
    await loadNamesAsync();
  }, [loadNamesAsync, setNodesOpen]);

  const onSelectFiles = useCallback(async (ids, mode, linkFromNode = null) => {
    if (mode === FILES_OPEN_ALL) {
      const data = await loadNodesAsync(ids);
      setResult(data.map((nodeData) => normalize(nodeData)));
    }

    if (mode === FILES_OPEN_ONE) {
      linkFromNode.externalId = ids[0];
      setResult((prevResult) => prevResult.map((nodeData) => normalize(nodeData)));
    }

    setNodesOpen(null);
    setLinkFrom(null);
  }, [loadNodesAsync, setNodesOpen, setResult]);

  // Accordion Events
  const onSelectPanel = useCallback((_id, open = false) => {
    if (expandedIds.indexOf(_id) < 0) {
      setExpandedIds([...expandedIds, _id]);
    } 
    else if (!open) {
      setExpandedIds(expandedIds.filter((panelId) => panelId !== _id));
    }
    setActiveId(_id);
  }, [expandedIds, setExpandedIds]);

  // Branches Events
  const onShowUrl = useCallback((url) => {
    setViewUrl(url);
  }, [setViewUrl]);

  const onAddExternal = useCallback(async (data, node) => {
    setNodesOpen(FILES_OPEN_ONE);
    setLinkFrom(node);
    await loadNamesAsync(data._id);
  }, [setNodesOpen, loadNamesAsync]);

  const onOpenExternal = useCallback(async (_id) => {
    if (result) {
      if (!result.find((data) => data._id === _id)) {
        const data = await loadNodesAsync([_id]);
        setResult(result.concat(data).map((nodeData) => normalize(nodeData)));
      }
      onSelectPanel(_id, true);
      const button = document.getElementById(`panel_${_id}-btn`);
      if (button && button.scrollIntoView) {
        button.scrollIntoView();
      }
    }
  }, [result, loadNodesAsync, onSelectPanel, setResult]);

  const onDeleteExternal = useCallback((node) => {
    node.externalId = null;
    setResult((prevResult) => prevResult.map((nodeData) => normalize(nodeData)));
  }, [setResult]);

  // Panel Events
  const onCreateStart = useCallback(async () => {
    setNodeSave({
      _id: null,
      title: 'New Logic File',
      node: {
        id: 'ID_1',
        name: 'New Node',
        link: '',
        externalId: null,
        children: []
      }
    });
  }, [setNodeSave]);

  const onSaveStart = useCallback(async (data) => {
    clearTimeout(saveTimeout.current);
    setNodeSave(data);
  }, [setNodeSave]);

  const onSaveComplete = useCallback(async (data) => {
    const isNew = data._id === null;
    const _id = await saveNodeAsync(data);
    if (isNew) {
      data._id = _id;
      setResult((prevResult) => [...(prevResult || []), data]);
    }
    setNodeSave(null);
    onSelectPanel(_id, true);
    setTimeout(() => {
      const button = document.getElementById(`panel_${_id}-btn`);
      if (button && button.scrollIntoView) {
        button.scrollIntoView();
      }
    }, 1000);
  }, [setResult, onSelectPanel, saveNodeAsync]);

  useEffect(() => {
    clearTimeout(saveTimeout.current);

    const save = async () => {
      clearTimeout(saveTimeout.current);

      if (result?.length && activeId) {
        const data = result.find((data) => data._id === activeId);
        if (data) {
          console.log(`Auto Save ${data.title}`);
          await saveNode(data);
        }
      }

      saveTimeout.current = setTimeout(save, 30 * 1000);
    };

    saveTimeout.current = setTimeout(save, 30 * 1000);
  }, [result, activeId, saveNodeAsync]);

  const onRemove = useCallback((_id) => {
    if (result) {
      setResult(result.filter((item) => item._id !== _id));
    }
  }, [result, setResult]);

  const onDelete = useCallback(async (_id) => {
    // eslint-disable-next-line no-restricted-globals
    const title = result.find((node) => node._id === _id)?.title;
    if (await confirmDeleteAsync(`The file "${title}" will be completely deleted. Continue?`)) {
      await deleteNodeAsync(_id);
      onRemove(_id);
    }
  }, [result, onRemove, confirmDeleteAsync, deleteNodeAsync]);

  const onMovePanel = useCallback((dragIndex, hoverIndex) => {
    setResult((prevResult) => 
      update(prevResult, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevResult[dragIndex]],
        ],
      }),
    );
  }, [setResult]);

  return (
    <LogicViewerLayout 
      viewUrl={viewUrl} 
      onOpenFiles={onLoadFiles}
      onNewFile={onCreateStart}
      onAttachFiles={() => setUploaderVisible(!uploaderVisible)}>
      <LoaderWrapper show items={[
          resolvedFile, 
          loadedNames, 
          loadedNodes,
          savedNode,
          deletedNode]}>
        <div>
          <div className='title-help-large text-uppercase d-flex align-items-center mb-2'>
            <span>Logic Viewer</span>
          </div>
          <Animation.Collapse in={uploaderVisible}>
            <div>
              <UploadDocsForm onRefresh={onUpload} isUnique />
            </div>
          </Animation.Collapse>
          <hr />
        </div>

        {result?.length > 0 && (
          <Accordion className="logic-accordion">
            {result.map((data, index) => (
              <ViewerPanel 
                index={index}
                key={data._id} 
                data={data}
                active={data._id === activeId}
                onClick={onSelectPanel}
                expanded={expandedIds.indexOf(data._id) >= 0} 
                onMovePanel={onMovePanel}
                onShowUrl={onShowUrl} 
                onDelete={onDelete}
                onSave={onSaveStart}
                onRemove={onRemove}
                onAddExternal={onAddExternal}
                onOpenExternal={onOpenExternal}
                onDeleteExternal={onDeleteExternal} 
              />
            ))}
          </Accordion>
        )}
      </LoaderWrapper>

      <OpenDialog 
        openMode={nodesOpen}
        onClose={() => setNodesOpen(null)}
        onOpen={(ids) => onSelectFiles(ids, nodesOpen, linkFrom)}
        namesData={loadedNames} 
        linkFrom={linkFrom} />

      <SaveDialog 
        data={nodeSave}
        onSave={onSaveComplete}
        onClose={() => setNodeSave(null)} /> 

      {confirmDeleteRender()}  
      
    </LogicViewerLayout>
  )
};

export default LogicViewerPage;