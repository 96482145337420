import classNames from 'classnames';
import { normalizeAmount } from './reportData';

const NutritionGroup = ({ category, items }) => {
  return (
    <div className="col-12 col-lg-6">
      <h3>
        {category}
      </h3>

      <div className="row">
        <div className="col-5">
          <div className="cell-header">
            Item
          </div>  
        </div>
        <div className="col-4">
          <div className="cell-header">
            Recommended
          </div>  
        </div>
        <div className="col-3">
          <div className="cell-header">
            Result
          </div>  
        </div>
      </div>

      {items.map((item) => (
        <div key={item.nutrient._id} className="row">
          <div className="col-5 bold">
            <div className="cell-panel">
              {item.nutrient.name}
            </div>
          </div>  
          <div className="col-4">
            <div className="cell-text">
              {item.norm ? normalizeAmount(item.norm) + item.nutrient.unitName : '--'}
            </div>  
          </div>
          <div className="col-3">
            <div className={classNames(item.total > item.norm ? 'cell-warn' : 'cell-text')}>
              {normalizeAmount(item.total)}{item.nutrient.unitName}
            </div>  
          </div>
        </div>
      ))}
    </div>
  );
};

export default NutritionGroup;