import { 
  IconButton, 
  ButtonToolbar,
} from 'rsuite';

const ReportHeader = ({ data, code }) => {
  const { operator } = data;

  return (
    <div>
      <div className="title-help-large text-uppercase d-flex align-items-center mb-2">
        <span>
          <b>{code}</b> medical report
        </span>
        <ButtonToolbar className="ms-2">
          <IconButton circle appearance="default" size="sm" icon={<span className="material-icons">print</span>} />
          <IconButton circle appearance="default" size="sm" icon={<span className="material-icons">email</span>} />
          <IconButton circle appearance="default" size="sm" icon={<span className="material-icons">download</span>} />
        </ButtonToolbar>
      </div>

      <h1>
        Hi, Dr. {operator.fullName}, here is your overview of {code} medical report
      </h1>

      <p>
        Hello Dr. {operator.fullName}, here are the details about <b>{code}</b> medical details outlining their symptoms, 
        latest clinical studies, genetics, current medications, and overall health
      </p>

      <div className="gradient-divider mb-4"></div>
    </div>
  );
};

export default ReportHeader;