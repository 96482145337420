import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useDropzone } from 'react-dropzone';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import AppsIcon from '@mui/icons-material/Apps';
import CloseIcon from '@mui/icons-material/Close';

import {
  Box,
  Button,
  IconButton
} from '@mui/material';

import LoaderWrapper from '../common/LoaderWrapper';

import { STORE } from '../../store';
import { useAsyncAppState } from '../../hooks/useAsyncAppState';
import { useCapability } from '../../hooks/useCapability';
import { uploadResultFiles } from '../../services/upload';

import './upload.css';

const DEFAULT_STATE = {
  selected: [],
  rejected: []
};

const UploadDocsForm = (props) => {
  useCapability('useUpload');

  const { onRefresh, isUnique, disabled } = props;

  const [uploadedFiles, uploadFilesAsync] = useAsyncAppState(
    STORE.areas.documents.upload, 
    uploadResultFiles);

  const [files, setFiles] = useState(DEFAULT_STATE);
  const filesRef = useRef(files);

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps
  } = useDropzone({
    maxFiles: 100,
    accept: {
      'application/octet-stream': ['.mm'],
      'text/html': ['.html', '.htm'],
      'text/csv': ['.csv'],
      'application/pdf': ['.pdf'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
    },
    validator: (file) => null
  });

  useEffect(() => {
    filesRef.current = files;
  }, [files]);

  useEffect(() => {
    const rejected = [...fileRejections];
    const selected = [...filesRef.current.selected];

    acceptedFiles.forEach((acceptedFile) => {
      if (selected.find((selectedFile) => selectedFile.name === acceptedFile.name)) {
        rejected.push({
          file: acceptedFile,
          error: {
            code: 'Duplicate',
            messsage: 'Already selected'
          }
        })
      }
      else {
        selected.push(acceptedFile);
      }
    });

    setFiles({
      selected,
      rejected
    })
  }, [acceptedFiles, fileRejections, setFiles]);

  const onDeleteDocument = useCallback((e, name) => {
    const selected = [...filesRef.current.selected];
    const rejected = [...filesRef.current.rejected];
    const index = selected.findIndex((selectedFile) => selectedFile.name === name);
    if (index >= 0) {
      selected.splice(index, 1);
      setFiles({
        selected,
        rejected
      })
    }
    e.stopPropagation();
  }, []);

  const onUpload = useCallback(async () => {
    if (!files.selected.length) {
      return;
    }

    const data = await uploadFilesAsync({ files: files.selected, isUnique });
    setFiles(DEFAULT_STATE);
    onRefresh(data);
  }, [files, isUnique, onRefresh, uploadFilesAsync]);

  return (
    <LoaderWrapper items={[uploadedFiles]} show>
      <Box className="vertical-flex">
        <div {...getRootProps({ className: 'dropzone mb-1' })}>
          <input {...getInputProps()} />
          <p>Drag 'n' drop some files here, or click to select files</p>
          <div className="w-100">
            {files.selected.map((selected) => (
              <div key={selected.name} className="icon-item" title={selected.name}>
                {selected.name.endsWith('.pdf') ? <PictureAsPdfIcon /> : <AppsIcon />}
                <span>{selected.name}</span>
                <IconButton edge="end" onClick={(e) => onDeleteDocument(e, selected.name)}>
                  <CloseIcon />
                </IconButton>
              </div>
            ))}
          </div>
        </div>
        <Button color="primary" size="large"
          variant="contained"
          disabled={!files.selected.length || disabled}
          onClick={onUpload}>
          Upload Document
        </Button>
      </Box>
    </LoaderWrapper>
  );
}

export default UploadDocsForm;
