import React, { useCallback, useEffect, useMemo } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, EffectCoverflow } from 'swiper/modules';

import './swipeSelect.css';

let globalSwipers = {};

const findDataItemIndex = (data, dataField, value, defaultValue = 0) => {
  const index = data.findIndex((dataItem) => String(dataItem[dataField]) === String(value));
  return index >= 0 ? index : defaultValue;
};

const SwipeSelect = (props) => {
  const {
    name,
    value,
    onChange,
    onBlur,
    data,
    textField,
    dataField,
    disabled,
    height,
    width,
    slidesPerView,
  } = props;

  const initialIndex = useMemo(
    () => findDataItemIndex(data, dataField, value),
    [data, dataField, value]);

  const items = useMemo(() => {
    return (data || []).map((dataItem) => (
      <SwiperSlide key={dataItem[dataField]} className="swiper-select-item">
        <span>
          {dataItem[textField]}
        </span>
      </SwiperSlide>
    ));
  }, [data, textField, dataField]);

  const onTouchEnd = useCallback((swipe) => {
    if (globalSwipers[name]) {
      const item = data[swipe.activeIndex];

      if (onChange) {
        onChange({
          type: 'change',
          srcElement: swipe.el,
          target: {
            name,
            value: item[dataField]
          }
        });
      }

      if (onBlur) {
        onBlur({
          type: 'blur',
          srcElement: swipe.el,
          target: {
            name,
            value: item[dataField]
          }
        });
      }
    }
  }, [name, data, dataField, onBlur, onChange]);

  useEffect(() => {
    if (globalSwipers[name]) {
      const newIndex = findDataItemIndex(data, dataField, value);
      if (newIndex !== globalSwipers[name].activeIndex) {
        globalSwipers[name].slideTo(findDataItemIndex(data, dataField, value));
      }
    }
  }, [name, data, dataField, value]);

  return (
    <Swiper
      modules={[FreeMode, EffectCoverflow]}
      effect="coverflow"
      coverflowEffect={{
        rotate: 0,
        depth: 75,
        stretch: 3
      }}
      freeMode={{
        enabled: true,
        sticky: true,
        momentum: false,
      }}
      slidesPerView={slidesPerView || 8}
      onSwiper={(swiper) => { globalSwipers[name] = swiper }}
      onTouchEnd={onTouchEnd}
      initialSlide={initialIndex}
      direction="vertical"
      className="swiper-select"
      slideActiveClass="swiper-slide-active"
      style={{ height, width }}
      disabled={disabled}
      centeredSlides>
      {items}
    </Swiper>
  );
}

export default SwipeSelect;
