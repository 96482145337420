import React, { useRef, useEffect } from 'react';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { IconButton } from 'rsuite';

import logo from '../../assets/img/albright-logo.svg';
import '../../css/index.scss';

const RATIO = 7 / 12;
const LogicViewerLayout = (props) => {
  const { children, viewUrl, rightBlock, onOpenFiles, onNewFile, onAttachFiles } = props;
  const popupRef = useRef(null);

  useEffect(() => {
    if (popupRef.current) {
      popupRef.current.close();
    }

    if (viewUrl) {
      const width = window.screen.width;
      let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
        width=${width * (1 - RATIO)},height=1000,left=${width * RATIO},top=0`;
      popupRef.current = window.open(viewUrl, '', params)
    }
  }, [viewUrl]);

  return (
    
    <div className="container-fluid rs-suite">
      <div className="row">
        <div className="col col-xl-7">
          <nav className="navbar">
            <a className="navbar-brand" href="/home">
              <img src={logo} alt="AlBright"></img>
            </a>
            <div>
              <IconButton 
                circle 
                onClick={onOpenFiles}
                icon={<span className="material-icons">folder_open</span>} 
                className="me-2"
                appearance="default" />

              <IconButton 
                circle 
                onClick={onAttachFiles}
                icon={<span className="material-icons">attach_file</span>} 
                className="me-2"
                appearance="default" />

              <IconButton 
                circle 
                onClick={onNewFile}
                icon={<span className="material-icons">post_add</span>} 
                appearance="default" />  
            </div>
          </nav>
          <main>
            <DndProvider backend={HTML5Backend}>
              {children}
            </DndProvider>  
          </main>
        </div>
        <div className="d-none d-xl-block col-xl-5">
          {rightBlock}
        </div>
      </div>
    </div>
  );
}

export default LogicViewerLayout;
