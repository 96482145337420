import classNames from 'classnames';

import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, FreeMode } from 'swiper/modules';
import moment from 'moment';

const SymptomsCards = (props) => {
  const { title, symptoms, colorClassName } = props;

  if (!symptoms?.length) {
    return null;
  }

  return (
    <Swiper 
      className={classNames('v-swiper', colorClassName)}
      direction="vertical"
      effect="coverflow"
      grabCursor
      centeredSlides
      slidesPerView="auto"
      modules={[EffectCoverflow, FreeMode]}
      slideActiveClass="active"
      coverflowEffect={{
        rotate: 20,
        depth: 50,
        stretch: 5,
        modifier: 2,
        slideShadows: false
      }}
      freeMode={{
        enabled: true,
        sticky: true,
        momentum: false,
      }}>
      {symptoms.map((data) => (
        <SwiperSlide key={data.id}>
          <h2>
            <span className={classNames('symptom-icon', title.icon)}></span>
            {title.label}
          </h2>

          <h3>Symptom</h3>
          <h2>
            {moment(data.start).format('MMM DD, YYYY | hh:mm A')}
          </h2>  
          <hr></hr>
          <span className="symptom-desc">
            {data.description}
          </span>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default SymptomsCards;