import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';

import LoaderWrapper from '../../../../components/common/LoaderWrapper';
import SymptomsCards from './SymptomsCards';
import './swiper.scss';

const SYMPTOMS_MAP = {
  'Dental': { label: 'Dental', icon: 'dental' },
  'Digestive': { label: 'Digestive', icon: 'digestive' },
  'Ear, nose, and throat': { label: 'Ear, Nose and Throat', icon: 'ear' },
  'Infectious disease': { label: 'Infectious Disease', icon: 'infection' },
  'Miscellaneous': { label: 'Miscellaneous', icon: 'misc' },
  'Musculoskeletal': { label: 'Musculoskeletal', icon: 'muscul' },
  'Neurology and Cognitive Burden': { label: 'Neurology & Cognitive Burden', icon: 'neurology-cognitive' },
  'Opthamology': { label: 'Opthamology', icon: 'eye' },
  'Reproductive system': { label: 'Reproductive System', icon: 'reproductive' },
  'Skin, hair, and nails': { label: 'Hair, Nails & Skin', icon: 'hair' },
  'Weight management': { label: 'Weight Management', icon: 'weight' },
  'Depression Inventory': { label: 'Depression Inventory', icon: 'depression' },
  'Fatigue Inventory': { label: 'Fatigue Inventory', icon: 'fatigue-inventory' },
  'Circulatory': { label: 'Circulatory', icon: 'heart' },
  'Fatigue': { label: 'Fatigue', icon: 'fatigue' },
  'Neurology': { label: 'Neurology', icon: 'neurology' },
  'Pain': { label: 'Pain', icon: 'pain' },
  'Sleep': { label: 'Sleep', icon: 'sleep' },
};

const SymptomsSwiper = ({ data }) => {
  const { symptoms } = data;

  return (
    <LoaderWrapper show>
      <h2 className="title-section">
        Symptoms
      </h2>

      <div className="symptoms-swiper">
        <Swiper
          slidesPerView="auto"
          pagination={{
            clickable: true,
          }}
          navigation
          modules={[Pagination, Navigation]}
          className="h-swiper"
        >
          <SwiperSlide className="slide-holder" />
          {Object.keys(symptoms)
            .filter((category) => category !== 'options')
            .map((category, index) => (
            <SwiperSlide key={category}>
              <SymptomsCards 
                title={SYMPTOMS_MAP[category] || {}}
                colorClassName={`color-${index % 6 + 1}`}
                symptoms={symptoms[category]} 
              />
            </SwiperSlide>
          ))}
          <SwiperSlide className="slide-holder" />
        </Swiper>
      </div>
    </LoaderWrapper>
  );
};

export default SymptomsSwiper;