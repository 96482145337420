import { useState, useCallback, useMemo } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';
import { Button, Dropdown, Modal } from 'rsuite';
import moment from 'moment';
import './indicator.scss';

import LoaderWrapper from '../../../../components/common/LoaderWrapper';
import { calcExposures, loadFileData } from '../../../../services/medReport';
import Indicator from './Indicator';
import classNames from 'classnames';

const getItems = (exposures, onDetails) => {
  return exposures.map((item) => (
    <SwiperSlide key={item.id}>
      <h1>
        {item.name}
      </h1>

      <h2>
        {item.source ? <a href={`#${item.source}`} onClick={(e) => onDetails(e, item)}>Result</a> : 'Result'} | 
        <b>{moment(item.date).format('MMM DD, YYYY')}</b>
      </h2>

      <Indicator 
        className="mt-5"
        step={item.step}
        range={item.range} 
        value={item.value}
        valueUnit={item.units}
        normalRange={item.normal}
      />
    </SwiperSlide>
  ))
};

const Exposures = ({ data }) => {
  const { exposures } = data;
  const defaultDate = useMemo(() => Object.keys(exposures)[0], [exposures]);
  const [items, setItems] = useState(calcExposures(exposures, defaultDate));
  const [allVisible, setAllVisible] = useState(false);
  const [currentDate, setCurrentDate] = useState(defaultDate);  
  const [details, setDetails] = useState(null);

  const onExpand = useCallback((showAll) => {
    setAllVisible(showAll);
  }, [setAllVisible]);

  const onDate = useCallback((date) => {
    setCurrentDate(date);
    setItems(calcExposures(exposures, date));
  }, [exposures, setItems, setCurrentDate]);

  const onDetails = useCallback(async (e, item) => {
    e.preventDefault();
    
    const data = await loadFileData(item.source);
    if (data?.length) {
      const lines = data.split('\n');
      
      setDetails({
        item,
        lines
      });
    }
  }, [setDetails]);

  return (
    <LoaderWrapper show>
      <h2 className="title-section">
        Exposures
      </h2>

      <Dropdown
        title={moment(currentDate).format('MMM DD, YYYY')}>
        {Object.keys(exposures).map((date) => 
          <Dropdown.Item key={date} onClick={() => onDate(date)}>
            {moment(date).format('MMM DD, YYYY')}
          </Dropdown.Item>
        )}
      </Dropdown>

      {!allVisible && (
        <div className="exposures-swiper">
          <Swiper
            slidesPerView="auto"
            pagination={{
              clickable: true,
            }}
            navigation
            modules={[Pagination, Navigation]}
            className="h-swiper"
          >
            <SwiperSlide className="slide-holder" />
            {getItems(items, onDetails)}
            <SwiperSlide className="slide-holder" />
          </Swiper>
        </div>
      )}

      {allVisible && (
        <div className="exposures-swiper">
          <div className="h-swiper is-pool">
            {getItems(items, onDetails)}
          </div>
        </div>
      )}
      
      <Button 
        appearance="default" 
        className="btn-gadget-2 mt-3" 
        onClick={() => onExpand(!allVisible)}>
        {allVisible ? 'Hide all' : 'View all'}
      </Button>

      <Modal className="round-modal"
        size="90vw"
        open={Boolean(details)} 
        onClose={() => setDetails(null)}>
        <Modal.Header />
        {details && (
          <Modal.Body className="green">
            <h3>{details.item.name}</h3>
            <h2>
              File: {details.item.source}
            </h2>
            <hr></hr>
            {details.lines.map((line, index) => 
              <pre className={classNames('console', line.indexOf(details.item.name) >= 0 && 'selected')} key={index}>{line}</pre>)}
          </Modal.Body>)}
      </Modal>
      
    </LoaderWrapper>
  );
};

export default Exposures;